import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "50",
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M32.1288 4.95386H17.8835V9.93483H32.1288V4.95386Z",
      fill: "#F06F02"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M34.8824 12.6884V4.95386H47.5V45.0463H2.5V4.95386H15.1299V12.6884H34.8824ZM23.0303 37.0263L36.5924 19.6847L33.4416 17.2206L22.6742 30.9886L16.4027 24.6683L13.5633 27.4858L23.0303 37.0263Z",
      fill: "#212529"
    }, null, -1)
  ])))
}
export default { render: render }